<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="z-index: 6">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="./../assets/Groaa1.png" width="75" height="40" alt="Logo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto nav-underline">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Home' }"
              @click="setActivePage('Home')"
              >Home</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Video' }"
              @click="setActivePage('Video')"
              >Video</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Benefits' }"
              @click="setActivePage('Benefits')"
              >Benefits</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Testimonials' }"
              @click="setActivePage('Testimonials')"
              >Testimonials</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Pricing' }"
              @click="setActivePage('Pricing')"
              >Pricing</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              :class="{ active: activePage === 'Contact Us' }"
              @click="setActivePage('Contact Us')"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center">
        <button
          class="btn btn-dark me-2 btn-sm"
          type="button"
          @click="callLogin()"
        >
          Login
        </button>
        <button
          class="btn btn-warning btn-sm"
          type="button"
          @click="callLogin()"
          style="color: white; font-weight: 600"
        >
          Sign Up
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      activePage: "Home", // Set the default active page
    };
  },
  methods: {
    setActivePage(page) {
      this.activePage = page;
    },
    callLogin(){
      window.open("https://ai.groaa.com/", "_blank");
    }
  },
};
</script>

<style scoped>
.nav-item .nav-link.active {
  color: #ffd700; /* Replace with your desired color */
}
.navbar {
  width: 85%;
  position: absolute;
  left: 7%;
  top: 4%;
  border-radius: 0.3rem;
}
.navbar-brand {
  margin-left: 1rem;
}

.d-flex.align-items-center {
  margin-right: 1rem;
}
</style>