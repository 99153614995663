<template>
  <div>
    <nav class="navbar fixed-top navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"
          ><img src="./../assets/Groaa2.png" width="75" height="40" alt="Logo"
        /></a>
      </div>
    </nav>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="height: 100vh"
    >
      <p style="font-size: x-large; font-weight: 600">GROAA</p>
      <p style="width: 900px">
        Meet Groaa, the AI that's here to do the heavy lifting on Instagram
        while you sip your chai/coffee. From answering DMs to handling comments
        faster than you can say "like, share, subscribe," Groaa's got your back.
        Want to be the first to try out the AI assistant that's as friendly as
        it is powerful?
      </p>
      <p style="width: 900px">
        Best part: As a freemium customer, you'll get 100 messages handled for
        free! Ready to experience the magic? Join the waitlist now and let Groaa
        start working for you!
      </p>
      <form id="emailLink">
        <div class="row" style="width: 400px">
          <div class="col-md-12">
            <label for="name" class="form-label"></label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Name"
              style="background-color: #fcf9ef"
            />
          </div>
        </div>
        <div class="row" style="width: 400px">
          <div class="col-md-12">
            <label for="emailId" class="form-label"></label>
            <input
              type="text"
              class="form-control"
              id="emailId"
              placeholder="Email Id"
              style="background-color: #fcf9ef"
            />
          </div>
        </div>
        <button
          class="btn btn-warning btn-md mt-3"
          type="submit"
          @click="callMethod()"
          style="color: black; font-weight: 600"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>
  <script>
export default {
  methods: {
    callMethod() {
      document
        .getElementById("emailLink")
        .addEventListener("click", function () {
          const name = document.getElementById("name").value;
          const email = document.getElementById("emailId").value;
          const recipient = "Groaa@mindverse.dev";
          const subject = "Want to join GROAA waitlist !!";
          const body = `Hi Team,

                        Please find below details - 

                        Name - ${name} 
                        Email - ${email}

                        Regards,
                        Your ${name}`;

                        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                        window.location.href = mailtoLink;
                        });
            },
        },
    };
</script>
  <style>
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 180%;
}
</style>