<template>
  <div>
    <nav class="navbar fixed-top navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"
          ><img src="./../assets/Groaa1.png" width="75" height="40" alt="Logo"
        /></a>
        <div
          class="d-flex align-items-center"
          style="
            color: #f4b301;
            font-weight: 600;
            font-size: 1.3rem;
            font-family: monospace;
          "
        >
          Coming Soon
        </div>
      </div>
    </nav>
    <div
      class="d-flex flex-column justify-content-center align-items-center"
      style="height: 90vh"
    >
      <img
        src="./../assets/Groaa2.png"
        width="265"
        height="140"
        alt="Centered Image"
      />
      <p class="showMsg">
        Growth-boosting Responsive Online Automation Assistant
      </p>
      <!-- <a href="https://forms.gle/tVEwKSmvuC9CNRCD8" target="_blank"> -->
      <a href="https://tally.so/r/3lLpbV" target="_blank">
        <button
          class="btn btn-warning btn-lg"
          type="button"
          style="color: black; font-weight: 600; font-family: monospace"
        >
          Join Waitlist
          <i class="bi bi-arrow-right ms-2"></i>
        </button>
      </a>
      <div class="mt-3">
        <a href="https://wa.me/919999221980?text=Hi!" target="_blank">
          <img
            src="./../../assets/whatsapp.png"
            style="width: 2rem; height: 2rem; margin-right: 1rem"
          />
        </a>
        <a
          href="https://www.instagram.com/groaaai?igsh=ODRqdHVsMnk5cjdq&utm_source=qr"
          target="_blank"
        >
          <img
            src="./../../assets/instagram.png"
            style="width: 1.9rem; height: 1.9rem"
          />
        </a>
      </div>
    </div>
    <footer>
      <div
        class="text-center p-4"
        style="background-color: rgba(0, 0, 0, 0.05)"
      >
        © 2024 All Rights Reserved GROAA / 
        <a class="text-reset fw-bold" href="https://www.privacypolicies.com/live/ff867b33-49d3-4751-af84-734735934579" target="_blank"
          >Privacy Policy</a
        >
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  methods: {
    gotojoinwaitlist() {
      this.$router.push("/joinwaitlist");
    },
  },
};
</script>
<style>
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 180%;
}
.showMsg {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: monospace;
  text-align: center;
}
@media (max-width: 767px) {
  .showMsg {
    font-size: 0.8rem;
  }
}
</style>