<template>
  <div style="overflow-x: hidden">
    <!-- <div
      class="bg-image"
      style="
        background-image: url('./assets/Rectangle.svg');
        min-height: 146vh;
        background-repeat: no-repeat;
        background-size: cover;
      "
    > -->
    <div class="firstDiv">
      <NavBar />
      <div class="introGroaa">
        <button class="btn btn-lg intro-button">Introducing GROAA</button>
      </div>

      <!-- Main Heading -->
      <div class="text-black text-left text-lg-start px-3 px-lg-0 main-heading">
        <h3 class="fw-bold display-6">
          Your Friendly Instagram Manager. Try for FREE!!
        </h3>
      </div>

      <!-- Features List -->
      <div
        class="text-black text-left text-lg-start px-3 px-lg-0 features-list"
      >
        <ul class="fs-5">
          <li class="pb-3">Automate Instagram messages & comments</li>
          <li class="pb-3">Save 90% Operational Cost & time</li>
          <li>Boost customer engagement and drive sales growth</li>
        </ul>
      </div>

      <!-- Subheading -->
      <div class="text-black text-center text-lg-start subheading">
        Never Miss a Message, Never Lose a Sale
      </div>

      <!-- Get Started Button -->
      <div class="text-center text-lg-start get-started-button">
        <button class="btn btn-lg" type="button">Get Started</button>
      </div>

      <!-- Carousel -->
      <div class="carousel-container">
        <div
          id="carouselHomeIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselHomeIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
              style="background-color: #f4b301"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselHomeIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
              style="background-color: #f4b301"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <video
                class="img-fluid videoClass2"
                autoplay
                loop
                muted
                style="width: 100%"
              >
                <source
                  src="./../../assets/videos/IMG_6970.MP4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div class="carousel-item">
              <video
                class="img-fluid videoClass2"
                autoplay
                loop
                muted
                style="width: 100%"
              >
                <source
                  src="./../../assets/videos/IMG_6971.MP4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Video Section -->
    <!-- <div class="mt-4 container">
      <h2 class="text-center fw-bold display-6 mb-4">
        Turn Engagement into Revenue -
        <span style="color: #ffca1d">Get Started with Automation</span>
      </h2> -->
    <!-- <div class="ratio ratio-16x9">
        <video class="img-fluid videoClass" autoplay loop muted>
          <source src="./../../assets/sampleVideo.mp4" type="video/mp4" />
        </video>
      </div> -->
    <!-- </div> -->

    <!-- Demo Button -->
    <!-- <div class="mt-4 d-flex justify-content-center">
      <button
        class="btn btn-xl"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleBookModal"
        style="color: white; background-color: #f4b301; width: 15rem"
      >
        Book a Demo
      </button>
    </div> -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleBookModal"
      tabindex="-1"
      aria-labelledby="exampleBookModallLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleBookModalLabel">
              Select Date & Time
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Modal content goes here -->
            <div class="row">
              <div class="col-md-12">
                <div class="form-outline datetimepicker">
                  <input
                    type="datetime-local"
                    class="form-control"
                    id="date&Time"
                    v-model="selectedDate"
                    placeholder="Select Date & Time"
                    style="background-color: #fcf9ef"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              style="color: white; background-color: #f4b301"
              @click="createGoogleCalendarEvent"
            >
              Book
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Details Section -->
    <div class="container mt-4">
      <h2 class="text-center fw-bold mb-4">
        The Details Matter: How Our Instagram Automation Makes a Difference
      </h2>
      <div class="row mb-5">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div
            id="carouselFirstIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselFirstIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
                style="background-color: #f4b301"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselFirstIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
                style="background-color: #f4b301"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <video
                  class="img-fluid videoClass1"
                  autoplay
                  loop
                  muted
                  style="width: 100%"
                >
                  <source
                    src="./../../assets/videos/IMG_6447.MP4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="carousel-item">
                <video
                  class="img-fluid videoClass1"
                  autoplay
                  loop
                  muted
                  style="width: 100%"
                >
                  <source
                    src="./../../assets/videos/IMG_6448.MP4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <!-- <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
              color="black"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
              color="black"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button> -->
          </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div>
            <h4 class="fw-bold">Auto-Magic Responses to Comments</h4>
            <div
              class="border-bottom border-warning my-3"
              style="width: 3rem"
            ></div>
            <p>
              Groaa's got you covered, day or night! Whether it's 2 AM or 2 PM,
              Groaa swoops in like a superhero, responding to every comment
              faster than you can say "auto-reply!" No delays, no missed
              messages - just seamless engagement, 24/7. Say goodbye to
              unanswered queries and hello to happy customers!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; background-color: #e3e3e3">
      <div class="container mt-4">
        <div class="row mb-5">
          <div class="col-lg-6 order-lg-2 mb-4 mb-lg-0">
            <div
              id="carouselSecondIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselSecondIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                  style="background-color: #f4b301"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselSecondIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                  style="background-color: #f4b301"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <video
                    class="img-fluid videoClass1"
                    autoplay
                    loop
                    muted
                    style="width: 100%"
                  >
                    <source
                      src="./../../assets/videos/IMG_7004.MP4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div class="carousel-item">
                  <video
                    class="img-fluid videoClass1"
                    autoplay
                    loop
                    muted
                    style="width: 100%"
                  >
                    <source
                      src="./../../assets/videos/IMG_7003.MP4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <!-- <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
              color="black"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
              color="black"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button> -->
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center order-lg-1">
            <div>
              <h4 class="fw-bold">Groaa's Got Your Back in Any Lingo!</h4>
              <div
                class="border-bottom border-warning my-3"
                style="width: 3rem"
              ></div>
              <p>
                Whether you're chatting in Hinglish, English, French, Tamil, or
                throwing in some Gen Z slang, Groaa's got you covered! From
                "vibe check" to "yeet," even if you're texting like a Gen Alpha
                with emojis and abbreviations, Groaa understands it all. So, go
                ahead, speak your language—Groaa is here to help, no matter how
                you roll!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <div class="row mb-5">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <div
            id="carouselThirdIndicators"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselThirdIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
                style="background-color: #f4b301"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselThirdIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
                style="background-color: #f4b301"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <video
                  class="img-fluid videoClass1"
                  autoplay
                  loop
                  muted
                  style="width: 100%"
                >
                  <source
                    src="./../../assets/videos/IMG_6325.MOV"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="carousel-item">
                <video
                  class="img-fluid videoClass1"
                  autoplay
                  loop
                  muted
                  style="width: 100%"
                >
                  <source
                    src="./../../assets/videos/IMG_6327.MOV"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <!-- <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
              color="black"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
              color="black"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button> -->
          </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div>
            <h4 class="fw-bold">Groaa: Your Instant Help Desk!</h4>
            <div
              class="border-bottom border-warning my-3"
              style="width: 3rem"
            ></div>
            <p>
              Got questions? Groaa's on it! Whether it's about prices, product
              details, payment options, or shipping policies—Groaa handles it
              all, faster than you can say “help me!” No more confusion, just
              quick and clear answers to keep your customers smiling.
            </p>
          </div>
        </div>
      </div>

      <!-- Benefits Section -->
      <!-- <div class="text-center mt-5">
        <h2 class="fw-bold">
          Our <span style="color: #ffca1d">Benefits</span>
        </h2>
      </div> -->
    </div>

    <div class="mt-5 py-4" style="background-color: #f4b301">
      <div class="row text-center stats-container">
        <div class="col-md-4 col-sm-12 mb-4">
          <img
            src="./../../assets/rating.png"
            class="iconClass mb-2"
            style="width: 4rem; height: 4rem"
          />
          <div class="font-weight-bold stat-item" style="font-size: 1.5rem">
            No of Customers
          </div>
          <span class="font-weight-light fontClass" style="font-size: medium"
            >Coming Soon</span
          >
        </div>
        <div class="col-md-4 col-sm-12 mb-4 stat-item">
          <img
            src="./../../assets/conversation.png"
            class="iconClass mb-2"
            style="width: 4rem; height: 4rem"
          />
          <div class="font-weight-bold stat-item" style="font-size: 1.5rem">
            No of Interactions
          </div>
          <span class="font-weight-light fontClass" style="font-size: medium"
            >Coming Soon</span
          >
        </div>
        <div class="col-md-4 col-sm-12 mb-4">
          <img
            src="./../../assets/calendar.png"
            class="iconClass mb-2"
            style="width: 4rem; height: 4rem"
          />
          <div class="font-weight-bold stat-item" style="font-size: 1.5rem">
            Business Days Saved
          </div>
          <span class="font-weight-light fontClass" style="font-size: medium"
            >Coming Soon</span
          >
        </div>
      </div>
    </div>

    <!-- Testimonials Section -->
    <div class="text-center mt-5">
      <h2 class="fw-bold">
        Our <span style="color: #ffca1d">Testimonials</span>
      </h2>
    </div>
    <div class="text-center mt-5">
      <p class="fs-5 fw-bold">
        Be the voice that starts it all! Your feedback could break the wall!
      </p>
    </div>

    <!-- @click="createCaptcha()" -->

    <div class="text-center mt-4">
      <a href="https://tally.so/r/3jWRpY" target="_blank">
        <button
          class="btn btn-xl"
          type="button"
          style="color: white; background-color: #f4b301; width: 15rem"
        >
          Share Your Thoughts
        </button>
      </a>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Share Your Thoughts
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Modal content goes here -->
            <div class="row">
              <div class="col-md-6">
                <label for="name" class="form-label"></label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Your Full Name"
                  style="background-color: #fcf9ef"
                />
              </div>
              <div class="col-md-6">
                <label for="contactno" class="form-label"></label>
                <input
                  type="text"
                  class="form-control"
                  id="contactno"
                  placeholder="Contact No"
                  style="background-color: #fcf9ef"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="emailId" class="form-label"></label>
                <input
                  type="text"
                  class="form-control"
                  id="emailId"
                  placeholder="Instagram Handle"
                  style="background-color: #fcf9ef"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="comment" class="form-label"></label>
                <textarea
                  class="form-control"
                  id="comment"
                  rows="3"
                  placeholder="Feedback"
                  style="background-color: #fcf9ef"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label for="captcha" class="form-label"></label>
                <input
                  type="text"
                  class="form-control"
                  id="captcha"
                  placeholder="Enter Captcha"
                  style="background-color: #fcf9ef"
                />
              </div>
              <div class="col-md-4">
                <div class="np-captcha-container">
                  <div class="np-captcha" v-if="captcha && captcha.length">
                    <div
                      v-for="(c, i) in captcha"
                      :key="i"
                      :style="{
                        fontSize: getFontSize() + 'px',
                        fontWeight: 800,
                      }"
                      class="np-captcha-character"
                    >
                      {{ c }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <button type="button" class="btn mt-4" @click="createCaptcha()">
                  <img
                    src="./../../assets/reload.png"
                    style="width: 1.5rem; height: 1.5rem"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              style="color: white; background-color: #f4b301"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-center mt-5">
      <h2 class="fw-bold">
        <span style="color: #ffca1d">Pricing</span>
      </h2>
    </div> -->
    <div class="text-center mt-5">
      <h2 class="fw-bold">
        <span style="color: #ffca1d">Contact Us</span>
      </h2>
    </div>
    <div class="text-center mb-5 mt-5 container">
      <div class="d-flex order-lg-1">
        <div>
          <h4 class="fw-bold">Let's Get Things Rolling!</h4>
          <p>
            Got questions or need more details? We're here for you! Whether it's
            about Groaa or anything else, feel free to chat with us or even
            Groaa directly. Reach out anytime via email, WhatsApp, or Instagram,
            and let's start a conversation! <br /><br />
            <img
              src="./../../assets/gmail.png"
              style="width: 1.5rem; height: 1.5rem; margin-right: 0.5rem"
            /><span style="font-weight: 600"> : Groaa@mindverse.dev </span
            ><br /><br />
            <a href="https://wa.me/919999221980?text=Hi!" target="_blank">
              <img
                src="./../../assets/whatsapp.png"
                style="width: 1.5rem; height: 1.5rem; margin-right: 1rem"
              />
            </a>
            <a
              href="https://www.instagram.com/groaaai?igsh=ODRqdHVsMnk5cjdq&utm_source=qr"
              target="_blank"
            >
              <img
                src="./../../assets/instagram.png"
                style="width: 1.5rem; height: 1.5rem"
              />
            </a>
          </p>
        </div>
      </div>
      <div class="text-center mt-2">
        <a href="https://tally.so/r/nGDWro" target="_blank">
          <button
            class="btn btn-xl"
            type="button"
            style="color: white; background-color: #f4b301; width: 15rem"
          >
            Contact Us
          </button>
        </a>
      </div>
    </div>
    <footer class="bg-light text-center py-4">
      <div class="container">
        <p class="mb-0">
          © 2024 All Rights Reserved GROAA /
          <a
            class="text-reset fw-bold"
            href="https://www.privacypolicies.com/live/ff867b33-49d3-4751-af84-734735934579"
            target="_blank"
            >Privacy Policy</a
          >
        </p>
      </div>
    </footer>
    <!-- <footer>
      <div
        class="text-center p-4"
        style="background-color: rgba(0, 0, 0, 0.05)"
      >
        © 2024 All Rights Reserved GROAA / 
        <a class="text-reset fw-bold" href="https://www.privacypolicies.com/live/ff867b33-49d3-4751-af84-734735934579" target="_blank"
          >Privacy Policy</a
        >
      </div>
    </footer> -->
  </div>
</template>

<script>
import NavBar from "./NavBar.vue";
export default {
  components: {
    NavBar,
  },
  data() {
    return {
      captchaLength: 5,
      captcha: [],
      selectedDate: "",
      eventTitle: "Demo",
      eventLocation: "Demo",
      eventDescription: "Demo",
    };
  },
  methods: {
    createCaptcha() {
      let tempCaptcha = "";
      for (let i = 0; i < this.captchaLength; i++) {
        tempCaptcha += this.getRandomCharacter();
      }
      this.captcha = tempCaptcha.split("");
    },
    getRandomCharacter() {
      const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const randomNumber = Math.floor(Math.random() * 36);
      return symbols[randomNumber];
    },
    getFontSize() {
      const fontVariations = [14, 18, 20, 26, 30];
      return fontVariations[Math.floor(Math.random() * 5)];
    },
    getRotationAngle() {
      const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
      return rotationVariations[Math.floor(Math.random() * 8)];
    },
    createGoogleCalendarEvent() {
      if (!this.selectedDate) {
        alert("Please select a date and time.");
        return;
      }

      const startDate = this.formatDateForGoogle(new Date(this.selectedDate));
      const endDate = this.formatDateForGoogle(
        new Date(new Date(this.selectedDate).getTime() + 60 * 60 * 1000)
      ); // Add 1 hour

      const googleCalendarUrl = `https://calendar.google.com/calendar/r/eventedit?dates=${startDate}/${endDate}&text=${encodeURIComponent(
        this.eventTitle
      )}&location=${encodeURIComponent(
        this.eventLocation
      )}&details=${encodeURIComponent(this.eventDescription)}`;

      window.open(googleCalendarUrl, "_blank");
    },
    formatDateForGoogle(date) {
      return new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, "");
    },
    // loadGAPI() {
    //   const script = document.createElement('script');
    //   script.src = 'https://apis.google.com/js/api.js';
    //   document.body.appendChild(script);
    //   script.onload = () => this.initGAPI();
    // },
    // initGAPI() {
    //   window.gapi.load('client:auth2', () => {
    //     window.gapi.auth2.init({
    //       client_id: '930900333779-3rthu154fk6hs9mk27gu3atm5jeo358p.apps.googleusercontent.com',
    //     });
    //   });
    // },
    // async createEvent() {
    //   await window.gapi.auth2.getAuthInstance().signIn();

    //   const event = {
    //     summary: this.eventTitle,
    //     start: {
    //       dateTime: new Date(this.eventDate).toISOString(),
    //       timeZone: 'America/Los_Angeles', // Adjust as needed
    //     },
    //     end: {
    //       dateTime: new Date(new Date(this.eventDate).getTime() + 60 * 60 * 1000).toISOString(), // 1 hour duration
    //       timeZone: 'America/Los_Angeles',
    //     },
    //   };

    //   const request = window.gapi.client.calendar.events.insert({
    //     calendarId: 'primary',
    //     resource: event,
    //   });

    //   request.execute(event => {
    //     console.log('Event created:', event);
    //   });
    // },
  },
  // mounted() {
  //   this.loadGAPI();
  // },
};
</script>

<style>
.videoClass {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
  border: #f4b301 0.15rem solid;
}
.videoClass1 {
  width: 80%;
  max-height: 499px;
}
.videoClass2 {
  width: 80%;
  max-height: 499px;
}
/* .introGroaa {
  position: absolute;
  top: 45%;
  left: 10%;
} */
.np-captcha-container {
  background: #fcf9ef;
  border-radius: 0.3rem;
  border: 1px solid rgb(222, 226, 230);
  width: 200px;
  height: 2.5rem;
  margin: 1.4rem auto;
  margin-bottom: 20px;
}
.np-captcha {
  font-size: 24px;
  margin-left: 1.5rem;
}

.np-captcha-character {
  display: inline-block;
  letter-spacing: 14px;
}
.introGroaa {
  position: absolute;
  left: 10%;
  top: 30%; /* Adjusted for better placement on smaller screens */
}

.intro-button {
  color: white;
  background-color: #f4b301;
  width: 100%;
  max-width: 300px; /* Limit button size on larger screens */
}

.main-heading {
  position: absolute;
  left: 10%;
  top: 40%; /* Adjusted to maintain spacing */
  font-size: 1rem; /* Adjusted for better readability on smaller screens */
}

.features-list {
  position: absolute;
  left: 10%;
  top: 50%;
  font-size: 1rem; /* Font size adjusted for mobile */
  font-weight: 500;
}

.subheading {
  position: absolute;
  left: 10%;
  top: 80%;
  font-weight: 600;
  font-size: 1.25rem; /* Adjusted for better readability */
}

.get-started-button {
  position: absolute;
  left: 10%;
  top: 90%;
}

.get-started-button .btn {
  color: white;
  background-color: #f4b301;
  width: 100%;
  max-width: 200px; /* Limit button size */
}

.carousel-container {
  position: absolute;
  left: 75%;
  top: 30%;
}
.firstDiv {
  width: 100%;
  background-color: #e3e3e3;
  min-height: 100vh;
}
.stats-container {
  height: 12rem;
  background-color: #f4b301;
}

.stat-item {
  font-size: 1.5rem;
  font-weight: 700;
}

.stat-item .iconClass {
  width: 4rem;
  height: 4rem;
}

.stat-item .fontClass {
  font-weight: 500;
  font-size: medium;
}

/* Responsive adjustments for mobile devices */
@media (max-width: 767px) {
  .stat-item {
    font-size: 1.2rem; /* Reduce text size for smaller screens */
  }

  .stat-item .iconClass {
    width: 3rem;
    height: 3rem; /* Reduce icon size for smaller screens */
  }

  .stats-container {
    height: auto; /* Let the height adjust naturally based on content */
    padding: 1rem 0; /* Add some padding for better spacing */
  }
}

@media (max-width: 767px) {
  /* Mobile screens */
  .introGroaa {
    left: 10%;
    top: 30%;
  }
  .main-heading {
    left: 10%;
    top: 40%;
    font-size: 1.5rem;
  }

  .features-list {
    left: 10%;
    top: 50%;
  }

  .subheading {
    left: 10%;
    top: 135%;
    font-size: 1.15rem;
  }

  .get-started-button {
    left: 10%;
    top: 140%;
  }

  .carousel-container {
    left: 21%;
    top: 75%;
  }
  .firstDiv {
    min-height: 150vh;
  }
  /* video {
  will-change: transform;
  transform: translateZ(0);
  } */
}
</style>
