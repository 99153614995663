<template>
  <div id="app">
    <router-view></router-view>
  <!-- <HomePage /> -->
</div>
</template>

<script>
//import HomePage from './components/HomePage.vue'
//import ComingSoon from './components/ComingSoon.vue'

export default {
  name: 'App',
  components: {
  //  HomePage
 //ComingSoon
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
} */
 #app {

 }
</style>
