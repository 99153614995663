import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import App from './App.vue';
import WaitlistForm from "./components/WaitlistForm.vue";
import ComingSoon from './components/ComingSoon.vue';
import HomePage from './components/HomePage.vue';
const app = createApp(App)

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: ComingSoon,
          },
          {
            path: "/home",
            component: HomePage,
          },
      {
        path: "/joinwaitlist",
        component: WaitlistForm,
      },
    ],
  });


  // app.use(VueLazyload, {
  //   lazyComponent: true,
  // });
app.use(router)
app.mount('#app')
